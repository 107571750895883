<template>
    <!-- <nav class="navbar navbar-dark lennuf-navbar">
        <div class="d-flex" v-if="!isGuest">
            <span>
                <a href="/"><picture>
                    <img src="/images/logo.svg" width="128" height="46">
                </picture></a>
            </span>
            <span class="navbar-item navbar-divider">|</span>
            <span class="navbar-item">
                Merchant<br>
                Administration System
            </span>
        </div>
        <template >
            <div v-if="$mq.tabletLg" class="d-flex navbar-right" @click="menuOpened = !menuOpened">
                <img src="//via.placeholder.com/50?text=O" alt="avatar" class="navbar-item navbar-avatar">
                <fa-icon icon="angle-down" size="lg" class="navbar-item navbar-icon"></fa-icon>
            </div>
            <div v-else class="d-flex">
                <span class="navbar-item">
                    Ваш менеджер<br>
                    {{ manager.full_name }}
                </span>
                <span class="navbar-item navbar-divider">|</span>
                <span class="navbar-item">
                    <a :href="`mailto:${manager.email}`">{{ manager.email }}</a><br>
                    <a :href="`tel:${manager.phone}`">{{ preparePhone(manager.phone) }}</a>
                </span>

                <notifications/>

                <span class="navbar-item navbar-icon">
                    <fa-icon
                            icon="envelope"
                            size="lg"></fa-icon>
                </span>

                <img src="//via.placeholder.com/50?text=O" alt="avatar" class="navbar-item navbar-avatar">
                <span class="navbar-item">
                    {{ operator.name }}<br>
                    <a href="#" @click="logout">Выйти</a>
                </span>
            </div>
        </template>

        <transition name="slide">
            <div v-if="$mq.tabletLg && menuOpened" class="mobile-menu">
                <div class="menu-block">
                    <span>{{ operator.name }}<br></span>
                    <span><a @click="logout">Выйти</a></span>
                </div>
                <div class="menu-block">
                    <span><fa-icon icon="comment-dots" size="lg"></fa-icon> Онлайн чат</span>
                    <span><fa-icon icon="envelope" size="lg"></fa-icon> Написать нам</span>
                </div>

                <span>Ваш менеджер</span>
                <span>{{ manager.full_name }}</span>
                <span><a :href="`mailto:${manager.email}`">{{ manager.email }}</a></span>
                <span><a :href="`tel:${manager.phone}`">{{ preparePhone(manager.phone) }}</a></span>
            </div>
        </transition>
    </nav> -->
    <div class="Header-layout">
        <div class="Header">
            <div class="Header-logo">
                <a href="/">
                    <img src="/images/logo.png" class="large">
                    <img src="/images/logo-mobile.png" class="small">
                </a>
            </div>
            <div class="Header-tools" v-if="!isGuest">
                <div class="Header-leftBar">
                    <manager class="only-desktop" />
                    <div class="Header-menuBtn only-mobile" @click="menuOpened = !menuOpened">
                        <img src="/images/menu.svg">
                    </div>
                </div><div class="Header-rightBar">
                        <notifications/>
                        <mail/>
                        <sign-out @exit="logout" class="only-desktop"/>
                </div>
            </div>
        </div>

        <!-- <div class="Header-menu only-desktop" v-if="!isGuest">
            <MainMenu></MainMenu>
        </div> -->
        <mobile-modal v-if="!isGuest" @close="menuOpened = false" :isOpen="menuOpened">
            <template v-slot:header>МЕНЮ</template>
            <template v-slot>
                <MainMenu></MainMenu>
            </template>
        </mobile-modal>

    </div>
</template>

<script>
    import '../../../images/logo_white.png';
    import Services from '../../../scripts/services/services';

    import modalMixin from '../../mixins/modal.js';

    import Manager from '../account-tools/manager.vue';
    import Notifications from '../account-tools/notifications.vue';
    import Mail from '../account-tools/mail.vue';
    import SignOut from '../account-tools/sign-out.vue';
    import MainMenu from '../main-menu/main-menu.vue';
    import MobileModal from '../modal-mobile.vue'

    export default {
        name: 'layout-header',
        components: {Manager, Notifications, Mail, SignOut, MainMenu, MobileModal},
        mixins: [modalMixin],
        props: {
            onIndex: {type: Boolean, default: false},
        },
        data() {
            return {
                menuOpened: false,
                messagesOpened: false,
            };
        },
        methods: {
            logout() {
                Services.net().post(this.route('logout'))
                .then(() => {
                    window.location.href = this.route('page.login');
                }, () => {
                    this.showMessageBox({title: 'Ошибка', text: 'Произошла ошибка. Попробуйте позже.'})
                })
            },
        },
    };
</script>
<style>

    .Header-layout .only-mobile
    {
        display: none;
    }

    .Header-layout .only-desktop
    {
        display: block;
    }

    @media screen and (max-width: 1023px)
    {

            .Header-layout .only-mobile
            {
                display: block;
            }

            .Header-layout .only-desktop
            {
                display: none;
            }
    }

    .Header
    {
        position: relative;
        background-color: #141116;
        height: 80px;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
    }

    .Header a, .Header a:hover
    {
        color: #fff;
    }

    .small
    {
        color: #7d7d7d;
        font-size: 12px;
    }

    .Header-logo
    {
        position: relative;
        text-align: center;
        height: 40px;
        line-height: 80px;
        z-index: 10;
    }

    .Header-logo img
    {
        height: 100%;
    }

    .Header-logo .large
    {
        display: auto;
    }

    .Header-logo .small
    {
        display: none;
    }

    @media screen and (max-width: 1290px)
    {
            .Header-logo .large
            {
                display: none;
            }

            .Header-logo .small
            {
                display: inline-block;
            }
    }

    .Header-tools
    {
        position: absolute;
        top: 0;
        display: flex;
        height: 80px;
        align-items: center;
    }

    .Header-tools .only-mobile
    {
        display: none;
    }

    .Header-tools .only-desktop
    {
        display: inline-block;
    }

    @media screen and (max-width: 1023px)
    {

            .Header, .Header-logo, .Header-tools
            {
                height: 48px;
            }

            .Header-logo
            {
                line-height: 48px;
                height: 29px;
            }

            .Header-tools .only-mobile
            {
                display: inline-block;
            }

            .Header-tools .only-desktop
            {
                display: none;
            }
    }

    .Header-tools > div
    {
        width: 50vw;
    }

    .Header-menuBtn {
        cursor: pointer;
        padding-left: 16px;
    }

    .Header-rightBar
    {
        text-align: right;
        padding-right: 41px;
    }

    .Header-rightBar > div
    {
        display: inline-block;
        padding-left: 28px;
    }

        @media screen and (max-width: 1023px)
    {
        .Header-rightBar
        {
            padding-right: 16px;
        }

        .Header-rightBar > div
        {
            display: inline-block;
            padding-left: 22px;
        }
    }


</style>

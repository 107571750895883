import Vue from 'vue'

const beautyTimestamp = timestamp => {
    //from 18:40:00 2020-09-14
    let datetime = timestamp.split(' ')
    let time = datetime[0].split(':')
    let date = datetime[1].split('-')

    let year = date[0]
    let mounth = date[1]
    let day = date[2]

    let hours = time[0]
    let minutes = time[1]
    //let seconds = time[2]

    //to 16.11.2019, 12:27
    return `${day}.${mounth}.${year}, ${hours}:${minutes}`
}

const beautyTimestamp2 = timestamp => {
    //from 18:40:00 2020-09-14
    let datetime = timestamp.split(' ')
    let time = datetime[1].split(':')
    let date = datetime[0].split('-')

    let year = date[0]
    let mounth = date[1]
    let day = date[2]

    let hours = time[0]
    let minutes = time[1]
    //let seconds = time[2]

    //to 16.11.2019, 12:27
    return `${day}.${mounth}.${year}, ${hours}:${minutes}`
}

Vue.filter('dateTime', beautyTimestamp)
Vue.filter('dateTime2', beautyTimestamp2)

export default beautyTimestamp
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Header-layout" },
    [
      _c("div", { staticClass: "Header" }, [
        _vm._m(0),
        _vm._v(" "),
        !_vm.isGuest
          ? _c("div", { staticClass: "Header-tools" }, [
              _c(
                "div",
                { staticClass: "Header-leftBar" },
                [
                  _c("manager", { staticClass: "only-desktop" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "Header-menuBtn only-mobile",
                      on: {
                        click: function($event) {
                          _vm.menuOpened = !_vm.menuOpened
                        }
                      }
                    },
                    [_c("img", { attrs: { src: "/images/menu.svg" } })]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "Header-rightBar" },
                [
                  _c("notifications"),
                  _vm._v(" "),
                  _c("mail"),
                  _vm._v(" "),
                  _c("sign-out", {
                    staticClass: "only-desktop",
                    on: { exit: _vm.logout }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.isGuest
        ? _c("mobile-modal", {
            attrs: { isOpen: _vm.menuOpened },
            on: {
              close: function($event) {
                _vm.menuOpened = false
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [_vm._v("МЕНЮ")]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [_c("MainMenu")]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2587972330
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Header-logo" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", { staticClass: "large", attrs: { src: "/images/logo.png" } }),
        _vm._v(" "),
        _c("img", {
          staticClass: "small",
          attrs: { src: "/images/logo-mobile.png" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        
        <div :class="{button: label}">
            <div class="mas-icon"  @click="notificationsOpened = !notificationsOpened">
                <div class="mas-icon-num" v-if="notificationsCount" :style="{color: color}">{{ notificationsCount }}</div>
                <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g v-if="!notificationsCount">
                <path d="M11 9.5C11.8284 9.5 12.5 8.82843 12.5 8C12.5 7.17157 11.8284 6.5 11 6.5C10.1716 6.5 9.5 7.17157 9.5 8C9.5 8.82843 10.1716 9.5 11 9.5Z" :fill="color"/>
                <path d="M17.5 8C17.5 8.82843 16.8284 9.5 16 9.5C15.1716 9.5 14.5 8.82843 14.5 8C14.5 7.17157 15.1716 6.5 16 6.5C16.8284 6.5 17.5 7.17157 17.5 8Z" :fill="color"/>
                <path d="M6 9.5C6.82843 9.5 7.5 8.82843 7.5 8C7.5 7.17157 6.82843 6.5 6 6.5C5.17157 6.5 4.5 7.17157 4.5 8C4.5 8.82843 5.17157 9.5 6 9.5Z" :fill="color"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.89543 0 0 0.895431 0 2L0 14C0 15.1046 0.89543 16 2 16H3L3 18C3 18.3466 3.17945 18.6684 3.47427 18.8507C3.76909 19.0329 4.13723 19.0494 4.44721 18.8944L10.2361 16L20 16C21.1046 16 22 15.1046 22 14L22 2C22 0.89543 21.1046 0 20 0L2 0ZM2 2L20 2L20 14L10.2361 14C9.92558 14 9.61935 14.0723 9.34164 14.2111L5 16.382V15C5 14.4477 4.55228 14 4 14H2L2 2Z" :fill="color"/>   
                </svg>
            </div>

            <div class="label" v-if="label" :style="{color: color}">УВЕДОМЛЕНИЯ</div>
        </div>
        
        <div class="notifications shadow" v-if="notificationsOpened">
            <mobile-modal  @close="notificationsOpened = false">
                <template v-slot:header>УВЕДОМЛЕНИЯ</template>
                <template v-slot>
                    <button type="button" class="notifications-btn btn btn-link p-2" @click="markNotifications()">Пометить всё как прочитанное</button>
                    <div class="notifications-wrapper overflow-auto">
                        <div class="notifications-item" v-for="notification in notifications">
                            <div class="card-body p-1" :class="notification.status === 2 ? 'notification-old' : ''">
                                <h6 class="card-title mb-0">{{ notification.payload.title }}</h6>
                                <small class="card-subtitle mb-2 text-muted">{{ notification.created_at }}</small>
                                <p v-if="notification.payload.body" class="card-text mt-2 mb-1" v-html="notification.payload.body"></p>
                            </div>
                        </div>
                    </div>
                </template>
            </mobile-modal>
        </div>
        
    </div>
</template>

<script>
    import Services from '../../../scripts/services/services';

    import MobileModal from '../modal-mobile.vue'

    export default {
        name: 'notifications',
        props: {
            label: {default: false},
            colorInverse: {default: false},
        },
        components: {MobileModal},
        data() {
            return {
                notificationsOpened: false,
                notifications: [],
                notificationsCount: 0,
            }
        },
        computed: {
            color () {
                return this.colorInverse ? '#141116' : '#FFFFFF'
            }
        },
        methods: {
            getNotifications() {
                const vm = this;
                Services.net().get(this.route('notifications.get'))
                .then(data => {
                    if(data) {
                        vm.notificationsCount = 0;
                        data.forEach((item, index) => {
                            this.$set(vm.notifications, index, item);
                            if(item.status === 1) {
                                vm.notificationsCount++;
                            }
                        })
                    }
                });
            },
            markNotifications() {
                const vm = this;
                Services.net().post(this.route('notifications.markAll'))
                .then(() => {
                    vm.getNotifications();
                });
            },
        },
        mounted() {
            this.getNotifications();

            setInterval(() => {
                this.getNotifications();
            }, 60 * 1000) // 1 Minute
        }
    };
</script>

<style>
    .notifications-item .card-body a,
    .notifications-item .card-body a:hover {
        color: #000;
        text-decoration: underline;
    }
</style>

<style scoped>
    .mas-icon {
        display: inline-block;
        cursor: pointer;
            /**
             * Для эксплорера
             */
            -ms-user-select: none;

            /**
             * Для мозилы
             */
            -moz-user-select: none;

            /**
             * Для конкверора
             */
            -khtml-user-select: none;

            /**
             * Для Сафари и Хрома
             */
            -webkit-user-select: none;
    }
    .mas-icon-num
    {
        position: absolute;
        width: 22px;
        text-align: center;
        font-size: 9px;
        font-weight: 800;
        cursor: pointer;
    }
    .button .mas-icon-num
    {
        padding-top: 5px;
    }

    .notifications {
        color: black;
        position: fixed;
        right: 16px;
        top: 64px;
        width: 320px;
        background: #fff;
        border-radius: 8px;
        height: 100%;
        border: 1px solid #DEE2E6;
        z-index: 9999;
        text-align: left;
        padding-bottom: 70px;
        overflow-y: scroll;
    }


    .notifications-wrapper {
        padding: 0px;
        max-height: 100%;
    }
    .notifications-btn
    {
        display: block!important;
        color: #000!important;
        padding: 16px 26px!important;
    }

    .notifications-item
    {
        padding: 16px 26px;
        border-top: 1px solid #DEE2E6;
    }
    .notifications-wrapper:not(:last-of-type) {
        border-bottom: 1px solid #E5E5E5;
    }
    .notification-badge {
        position: absolute;
        margin: 6px 0 0 -8px;
    }
    .notification-old {
        background: #eee;
        opacity: 0.5;
    }

    .button
    {
        display: flex;
        align-items: center;
        padding: 16px;
    }
    .label
    {
        text-transform: uppercase;
        padding-left: 16px;
        font-weight: 500;
    }
</style>
<template>
    <nav class="sidebar">
        <div class="mobile">
            <div class="menu">
                <menu-item v-for="(item, index) in menu" :item="item" :key="index"></menu-item>
            </div>
            <div class="footer">
                <div class="tools">
                    <sing-out @exit="logout" :label="true" :colorInverse="true" />
                    <notifications :label="true" :colorInverse="true" />
                    <mail :label="true" :colorInverse="true" />
                </div>
                <div class="manager">
                    <manager :mobile="true" :colorInverse="true" />
                </div>
            </div>
        </div>
        <div class="desktop">
            <HorizontalMenuItem v-for="(item, index) in menu" :item="item" :key="index"></HorizontalMenuItem>
        </div>
    </nav>
</template>

<script>
    import MenuItem from './menu-item.vue';
    import SingOut from '../account-tools/sign-out.vue'
    import Notifications from '../account-tools/notifications.vue'
    import Mail from '../account-tools/mail.vue'
    import Manager from '../account-tools/manager.vue'
    import HorizontalMenuItem from './horizontal-menu-item.vue';
    import Services from "../../../scripts/services/services";

    export default {
        name: 'main-menu',
        components: {
            MenuItem, SingOut, Notifications, Mail, Manager, HorizontalMenuItem
        },
        methods: {
          logout() {
            Services.net().post(this.route('logout'))
                .then(() => {
                  window.location.href = this.route('page.login');
                }, () => {
                  this.showMessageBox({title: 'Ошибка', text: 'Произошла ошибка. Попробуйте позже.'})
                })
          },
        },
        computed: {
            menu() {
                return this.$store.state.layout.menu;
            },
        },
    };
</script>
<style>
    .sidebar {
        background-color: #fbfbfb!important;
    }

    .mobile
    {
        display: none;
    }

    .desktop
    {
        display: block;
        padding-left: 12px;
    }

    @media screen and (max-width: 1023px)
    {
        .sidebar {
            background-color: #fff!important;
        }

        .mobile
        {
            display: block;
        }

        .desktop
        {
            display: none;
        }
            
    }

    .menu, .footer .tools
    {
        padding-bottom: 16px;
        border-bottom: 1px solid #EFF1F3;
    }

    .footer .tools
    {
        padding-top: 16px;
    }

    .footer .manager
    {
        padding: 36px 0 45px 0;
    }

</style>